
export const $environments = ['local', 'debug', 'testing', 'staging', 'live']

export const $appConfig = {
  app: {
    name: 'Pelican',
    env: $environments[4],
  },
  api: {
    url: 'https://api.pelicantrivia.info',
  },
  layout: {
  },
}
